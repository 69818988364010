<template>
    <div>
        <div class="my_treat_page">
            <div class="name_info">
                <div class="name_num">
                    <span class="left">{{this.name}}</span>
                </div>
                <div class="account_info">
                    <div class="sex_age_phone">
                        <span>{{this.sex}} . {{this.age}}岁</span>
                        <span v-if="this.phone">{{this.phone}}</span>
                    </div>
                    <div class="account">
                        <span>工号：{{dispname}}</span>
                    </div>
                </div>

            </div>
            <div class="selected_acupoint" :class="spread ? 'spread' : ''" ref="selectedAcupoint">
                <van-badge v-for="item in selectItem" :key="item.id" class="van_selected">
                    <div class="child">{{item.c_text}}</div>
                    <template #content>
                        <van-icon name="cross" class="badge-icon" @click="delAcupoint(item.id, item.index)" />
                    </template>
                </van-badge>
            </div>
            <div class="acupoint_list">
                <van-tree-select
                        height="371px"
                        :items="items"
                        :active-id.sync="activeIds"
                        @click-item="selectedAcupoint"
                        :main-active-index.sync="activeIndex"
                />
            </div>
            <div class="ctrl_bottom" v-if="show">
                <button @click="back">返回</button>
                <button @click="addAcupoint">添加新穴位</button>
                <button v-if="btnShow && spread" @click="shrinkClick">收缩</button>
                <button v-if="btnShow && !spread" @click="spreadClick">展开</button>
                <button class="exec_class" @click="submitMatch">确认配穴</button>
            </div>

        </div>
        <van-dialog v-model="showDialog" title="添加新穴位"
                    confirm-button-text="添加" show-cancel-button
                    :before-close="beforeClose"
                    @confirm="submitAddAcupoint">
            <van-form validate-first class="dialog-form">
                <van-field name="radio" label="选择部位">
                    <template #input>
                        <van-radio-group v-model="partcode" direction="horizontal">
                            <van-radio class="radio_style" v-for="item in items" :key="item.value" :name="item.value">{{item.text}}</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-field
                        v-model="acupointname"
                        label="穴位名称"
                        name="acupointname"
                        placeholder="请输入穴位名称"
                />
            </van-form>

        </van-dialog>
    </div>

</template>

<script>
    import { wxacupointqry,wxacupointopr,wxaddacupoint } from '@/api/medical'
    import { mapGetters } from 'vuex'
    import { Toast } from 'vant'
    export default {
        computed: {
            ...mapGetters([
                'username','dispname'
            ]),
        },
        data() {
            return {
                execstatus: '0',
                serviceid: '',
                userid: '',
                customerid:'',
                name:'',
                sex:'',
                age:'',
                phone:'',
                acupointname:'',
                partcode:'B0001',
                show: true,
                showDialog: false,
                isAddLoading: true,
                spread: false,
                btnShow: false,
                initHeight: 0,
                items: [],
                activeIds: [],
                activeIndex: 0,
                selectItem: []
            }
        },
        mounted () {
            this.$store.dispatch('hideOrShowNav', false)
            this.serviceid = this.$route.query.serviceid || ''
            this.name = this.$route.query.name || ''
            this.sex = this.$route.query.sex || ''
            this.age = this.$route.query.age || ''
            this.phone = this.$route.query.phone || ''
            this.userid = this.$route.query.userid || ''
            this.customerid = this.$route.query.customerid || ''
            this.execstatus = this.$route.query.execstatus || ''
            let oldAcupointIds = this.$route.query.acupointids || ''
            let acupointIds = oldAcupointIds.split(',')
            acupointIds.forEach(item => {
                this.activeIds.push(parseInt(item))
            })
            this.getAcupointInfo()
        },
        methods: {
            // 获取穴位信息
            getAcupointInfo(){
                Toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                })
                let data = {
                    username: this.username
                }
                wxacupointqry(data).then(response => {
                    Toast.clear()
                    let list = response.response_body.list
                    for(var i = 0; i < list.length; i++) {
                        let part = list[i];
                        let childrens = [];
                        if(part.acupointlist && part.acupointlist.length > 0) {
                            let badge = 0
                            for(var j = 0; j < part.acupointlist.length; j++) {
                                let children = {
                                    'id' : part.acupointlist[j].acupointid,
                                    'text' : part.acupointlist[j].acupointname,
                                    'selected' : 0
                                }
                                let k = this.activeIds.findIndex(item =>{
                                    if(item==children.id){
                                        return true
                                    }
                                })
                                if(k >= 0) {
                                    badge++
                                    children.selected = 1
                                    let item = {
                                        'index': i,
                                        'text': part.partname,
                                        'id': part.acupointlist[j].acupointid,
                                        'c_text': part.acupointlist[j].acupointname
                                    }
                                    this.selectItem.push(item)
                                }
                                childrens.push(children)
                            }
                            let item = {
                                'text' : part.partname,
                                'value' : part.partcode,
                                'children' : childrens,
                                'badge': badge
                            }
                            this.items.push(item);
                        }
                    }
                    this.initHeight = this.$refs.selectedAcupoint.offsetHeight;
                    this.compareHeight()
                })

            },
            //返回
            back() {
                this.$router.go(-1)
            },
            //配穴
            match() {
                this.$router.push({
                    path: '/treatExec',
                    query: {
                        serviceid: this.serviceid,
                        oprtype: '3',
                        doctor: this.doctor,
                        roomid: this.roomid,
                        doctorname: this.doctorname
                    }
                })
            },
            //选择穴位操作
            selectedAcupoint(data) {
                if(data.selected === 0) {
                    data.selected = 1;
                    this.items[this.activeIndex].badge++
                    let item = {
                        'index': this.activeIndex,
                        'text': this.items[this.activeIndex].text,
                        'id': data.id,
                        'c_text': data.text
                    }
                    this.selectItem.push(item)
                } else {
                    data.selected = 0;
                    this.items[this.activeIndex].badge--
                    let index = this.selectItem.findIndex(item =>{
                        if(item.id==data.id){
                            return true
                        }
                    })
                    this.selectItem.splice(index,1)
                }
                this.compareHeight()
            },
            delAcupoint(id,index) {
                this.items[index].badge--
                let i = this.selectItem.findIndex(item =>{
                    if(item.id==id){
                        return true
                    }
                })
                this.selectItem.splice(i,1)
                this.compareHeight()
                let j = this.items[index].children.findIndex(item =>{
                    if(item.id==id){
                        return true
                    }
                })
                //修改数组中selected值，防止下次选中的时候
                this.$set(this.items[index].children[j], 'selected', 0)

                let k = this.activeIds.findIndex(item =>{
                    if(item == id){
                        return true
                    }
                })
                this.activeIds.splice(k,1)
            },
            submitMatch() {
                if(this.activeIds && this.activeIds.length > 0) {
                    let acupointids = this.activeIds.join(",");
                    let acupointdesc = '';
                    for(var i =0; i < this.items.length; i++) {
                        if(this.items[i].children) {
                            for(var j = 0; j < this.items[i].children.length; j++) {
                                if(this.items[i].children[j].selected == 1) {
                                    acupointdesc = acupointdesc + this.items[i].children[j].text + '，'
                                }
                            }
                        }
                    }
                    if(acupointdesc.length > 0) {
                        acupointdesc = acupointdesc.substring(0, acupointdesc.length - 1)
                    }
                    this.$dialog.confirm({
                        title: null,
                        message: '确认执行本次的配穴方案？',
                    })
                        .then(() => {
                            let data = {
                                username: this.username,
                                serviceid: this.serviceid,
                                acupointids: acupointids,
                                acupointdesc: acupointdesc
                            }
                            wxacupointopr(data).then(() => {
                                Toast.success('配穴成功！');
                                this.show = false
                                let second = 2;
                                const timer = setInterval(() => {
                                    second--;
                                    if (second === 0) {
                                        clearInterval(timer);
                                        this.back()
                                    }
                                }, 1000);

                            })
                        })
                        .catch(() => {
                            // on cancel
                        });
                } else {
                    Toast.fail('请选择穴位!');
                }

            },
            addAcupoint() {
                this.showDialog = true
            },
            beforeClose(action, done) {
              if(action == 'confirm') {
                  done(false)
              } else {
                  done()
              }
            },
            submitAddAcupoint() {
                if(this.isAddLoading) {
                    if(this.acupointname == null || this.acupointname.trim().length === 0) {
                        Toast.fail('请填写穴位名称!');
                        return false;
                    }
                    this.isAddLoading = false
                    this.$dialog.confirm({
                        title: null,
                        message: '确认保存当前穴位信息？',
                    })
                        .then(() => {
                            let data = {
                                username: this.username,
                                partcode: this.partcode,
                                name: this.acupointname
                            }
                            wxaddacupoint(data).then(response => {
                                Toast.success('添加成功!');
                                let second = 2;
                                const timer = setInterval(() => {
                                    second--;
                                    if (second === 0) {
                                        clearInterval(timer);
                                        this.showDialog = false
                                        this.isAddLoading = true
                                        let k = this.items.findIndex(item =>{
                                            if(item.value == this.partcode){
                                                return true
                                            }
                                        })
                                        if(k >= 0) {
                                            let children = {
                                                'selected' : 0,
                                                'text': this.acupointname,
                                                'id': response.response_body.id
                                            }
                                            this.items[k].children.push(children)
                                        }
                                        this.acupointname = ''
                                    }
                                }, 1000);

                            }).catch(() => {
                                this.isAddLoading = true
                            })
                        })
                        .catch(() => {
                            // on cancel
                            this.isAddLoading = true
                        });
                }
            },
            spreadClick() {
                this.spread = true
            },
            shrinkClick() {
                this.spread = false
            },
            compareHeight() {
                let _this = this
                setTimeout(function () {
                    let scrollHeight = _this.$refs.selectedAcupoint.scrollHeight;
                    let offsetHeight = _this.$refs.selectedAcupoint.offsetHeight;
                    if(scrollHeight > offsetHeight || offsetHeight > _this.initHeight) {
                        _this.btnShow = true
                    } else if(_this.initHeight >= offsetHeight) {
                        _this.btnShow = false
                    }
                }, 100)

            }
        },
    }
</script>

<style lang="scss" scoped>
    /deep/.van-tree-select__selected {
        position: absolute !important;
    }
    .dialog-form {
        min-height: 300px;
        padding: 20px 0px;
    }
    .radio_style {
        margin-bottom: 15px;
        margin-right: 20px;
    }
    .spread {
        height: auto !important;
        min-height: 170px;
    }
</style>